<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="6"
      sm="6"
    >
      <v-text-field
        hide-details
        dense
        outlined
        disabled
        :prefix="$store.state.app.clasesVuelos[0].equipaje_bodega.um_dimension"
        :suffix="minPrice"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="1"
      sm="1"
    >
      <p class="pt-2 ml-1">
        -
      </p>
    </v-col>

    <v-col
      cols="12"
      md="5"
      sm="5"
    >
      <v-text-field
        v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_dimension[pos].dimension_max"
        hide-details
        dense
        outlined
        type="number"
        min="1"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import { mdiTrashCan } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      icons: {
        mdiTrashCan,
      },
      pesos: [],
    }
  },
  computed: {
    minPrice() {
      if (this.pos > 0) {
        // eslint-disable-next-line camelcase
        const { dimension_max } = this.$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_dimension[this.pos - 1]

        return parseInt(dimension_max, 10) + 1
      }

      return this.$store.state.app.clasesVuelos[0].equipaje_bodega.lineal_dimensiones
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(['deleteMaletaBodegaVuelo']),
    changeIncluida() {
      if (this.clase.equipaje_bodega.maletas[this.pos].incluida) {
        this.clase.equipaje_bodega.maletas[this.pos].permitida = true
      }
    },
  },
}
</script>
