<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <v-checkbox
        v-model="clase.equipaje_cabina.aplica_adult"
        class="my-checkbox"
        :label="$t('lbl.equipaje_cabina.adul')"
        hide-details
      ></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <v-checkbox
        v-model="clase.equipaje_cabina.aplica_child"
        class="my-checkbox"
        :label="$t('lbl.equipaje_cabina.child')"
        hide-details
      ></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <v-checkbox
        v-model="clase.equipaje_cabina.aplica_inf"
        class="my-checkbox"
        :label="$t('lbl.equipaje_cabina.inf')"
        hide-details
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
  },
}
</script>

<style scoped>
::v-deep .my-checkbox .v-label {
    font-size: 12px !important;
    line-height: 1;
    min-height: 8px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
