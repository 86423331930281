<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="12"
      sm="12"
    >
      <v-text-field
        v-model="clase.equipaje_bodega.exceso_equipaje[pos].price"
        type="number"
        min="0"
        hide-details
        dense
        outlined
        prefix="$"
        :disabled="pesoNoAdmitido"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      icons: {
        mdiTrashCan,
      },
      pesos: [],
    }
  },
  computed: {
    pesoNoAdmitido() {
      if (parseInt(this.$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_equipaje[0].peso_max, 10) <= 0
        || this.clase.equipaje_bodega.number_peso > parseInt(this.$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_equipaje[this.pos].peso_max, 10)
        || !this.$store.state.app.clasesVuelos[0].equipaje_bodega.exceso_equipaje[this.pos].peso_max) {
        return true
      }

      return false
    },
  },
  methods: {
    ...mapMutations(['deleteMaletaBodegaVuelo']),
  },
}
</script>
