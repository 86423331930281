<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="8"
      sm="8"
    >
      <v-text-field
        v-if="clase.equipaje_personal.incluido && clase.equipaje_personal.permitido"
        :label="$t('lbl.incluida')"
        hide-details
        dense
        outlined
        disabled
      ></v-text-field>
      <v-text-field
        v-else-if="!clase.equipaje_personal.incluido && !clase.equipaje_personal.permitido"
        :label="$t('lbl.noPermitida')"
        hide-details
        dense
        outlined
        disabled
      ></v-text-field>
      <v-text-field
        v-else
        v-model="clase.equipaje_personal.price"
        type="number"
        min="0"
        hide-details
        dense
        outlined
        prefix="$"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="4"
      sm="4"
    >
      <v-switch
        v-model="clase.equipaje_personal.incluido"
        :label="$t('lbl.incluida')"
        dense
        hide-details
        class="mb-0 mt-0 ml-2"
        @change="changeincluido"
      ></v-switch>
      <v-switch
        v-model="clase.equipaje_personal.permitido"
        :label="$t('lbl.permitida')"
        dense
        hide-details
        class="mb-0 mt-0 ml-2"
        @change="changepermitido"
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
  },
  methods: {
    changeincluido() {
      if (this.clase.equipaje_personal.incluido) {
        this.clase.equipaje_personal.permitido = true
      }
    },
    changepermitido() {
      if (!this.clase.equipaje_personal.permitido) {
        this.clase.equipaje_personal.incluido = false
      }
    },
  },
}
</script>
