<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="10"
      sm="10"
    >
      <v-select
        v-if="!isLoading"
        v-model="clase.class_id"
        :items="clases"
        item-text="name"
        item-value="id"
        :label="$t('lbl.clase')"
        outlined
        dense
        hide-details
        @change="changeClase"
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      md="2"
      sm="2"
    >
      <v-tooltip
        v-if="pos !== 0"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="error"
            @click="deleteClaseVuelo(pos)"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    airline: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      clases: [],
      icons: {
        mdiTrashCan,
      },
    }
  },

  created() {
    this.load()
  },
  methods: {
    ...mapMutations(['adicionarClaseVuelo', 'deleteClaseVuelo', 'updateClaseVuelo', 'adicionarPrecioAdicionalVuelo']),

    load() {
      // this.clases = this.airline.clases
      // this.isLoading = false

      this.axios.get(`nom_clase_flights?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
        },
      })
        .then(res => {
          const clasess = res.data.data

          // PARA COGER SOLO LAS CLASES DE LA AEROLINEA SELECCIONADA Q TENGAN TARIFAS
          this.axios
            .get(`nom_tarifa_flights?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              const tarifas = response.data.data
              clasess.forEach(ccc => {
                tarifas.forEach(tari => {
                  if (ccc.id === tari.clase_id && tari.aerolinea_id === this.airline.id) {
                    if (!this.clases.find(c => c.id === ccc.id)) {
                      this.clases.push(ccc)
                    }
                  }
                })
              })
            })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    changeClase() {
      this.$store.state.app.loadTarifasVuelos = true

      setTimeout(() => {
        this.$store.state.app.loadTarifasVuelos = false
      }, 150)
    },
  },
}
</script>
