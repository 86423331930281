<template>
  <v-select
    v-if="!isLoading"
    v-model="clase.equipaje_bodega.dimensiones"
    :items="dimensiones"
    item-text="nameUM"
    item-value="id"
    outlined
    dense
    hide-details
    :suffix="clase.equipaje_bodega.um_dimension"
    @change="changeSelect()"
  ></v-select>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      dimensiones: [],
    }
  },

  created() {
    this.load()
  },
  methods: {

    load() {
      this.axios.get(`nom_dimensiones_flights?perfil_slug=${sessionStorage.getItem('perfil')}&tipo=bodega`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
        },
      })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            if (element.tipo === 'Equipaje facturado') {
              this.dimensiones.push({
                id: element.id,
                nameUM: `${element.nameUM}`,
                um: element.um,
                lineal: element.lineal,
              })
            }
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    changeSelect() {
      if (this.clase.equipaje_bodega.dimensiones) {
        this.$store.state.app.umDimensionBodega = this.dimensiones.filter(e => e.id === this.clase.equipaje_bodega.dimensiones)[0].um
        this.clase.equipaje_bodega.um_dimension = this.dimensiones.filter(e => e.id === this.clase.equipaje_bodega.dimensiones)[0].um
        this.clase.equipaje_bodega.lineal_dimensiones = this.dimensiones.filter(e => e.id === this.clase.equipaje_bodega.dimensiones)[0].lineal
      }
    },
  },
}
</script>
